<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    label-width="100px"
    label-position="left"
  >
    <el-form-item label="名称">
      <el-input v-model="form.name"></el-input>
    </el-form-item>

    <el-form-item label="头像" prop="headerUrl">
      <el-input v-model="form.headerUrl"></el-input>
    </el-form-item>

    <el-form-item label="预览">
      <el-avatar shape="square" :size="90" :src="form.headerUrl"></el-avatar>
    </el-form-item>

    <el-form-item label="地址" prop="address">
      <el-input v-model="form.address"></el-input>
    </el-form-item>

    <el-form-item label="系统" prop="system">
      <el-input v-model="form.system"></el-input>
    </el-form-item>

    <el-form-item label="浏览器" prop="browser">
      <el-input v-model="form.browser"></el-input>
    </el-form-item>

    <el-form-item label="账号" prop="account">
      <el-input v-model="form.account"></el-input>
    </el-form-item>

    <el-form-item label="密码" prop="password">
      <el-input v-model="form.password"></el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="edit">更新</el-button>
      <el-button type="primary" @click="onSubmit">刷新 缓存信息</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "yaimeraaa",
        headerUrl:
          "https://oss.yaimer.com/static/yaimerCommentHeadPortaid/33fa9c55-023f-48f7-8d36-1d8b3d400d38.jpg",
        address: "东京 香草寺",
        system: "Windows 11",
        browser: "Chrome 94.20",
        account: "admin",
        password: "12312312312",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        headerUrl: [
          { required: true, message: "请输入头像地址", trigger: "change" },
        ],
        system: [
          { required: true, message: "请输入大概系统", trigger: "change" },
        ],
        browser: [
          { required: true, message: "请输入大概浏览器", trigger: "change" },
        ],
        address: [
          { required: true, message: "请输入大概地址", trigger: "change" },
        ],
        account: [
          {
            required: true,
            message: "请输入管理员登录账号",
            trigger: "change",
          },
        ],
        password: [
          { required: true, message: "请输入管理员密码", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let that = this;
      this.$axios.get("/adminApi/about").then(function (resp) {
        that.form = resp.data.data;
      });
    },
    edit() {
      let that = this;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$axios.post("/adminApi/editAbout",this.form).then(function (resp) {
            if (resp.data.code === 200) {
              that.$notify.success({
                title: resp.data.msg,
                message: resp.data.data,
              });
            }
          });
        } else {
          that.$notify.error({
            title: "warn",
            message: "完整填写内容！",
          });
        }
      });
    },
    onSubmit() {
      console.log("new---------");
    },
  },
};
</script>

<style>
</style>